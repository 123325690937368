/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SecondaryDetermination {
  tpCarbonFactor: number;
  energyCarbonUnderTarget: number;
  carbonUnderTarget: number;
  co2Emissions: number;
  priBuyOutCarbon: number;
  prevBuyOutCo2?: number;
  prevSurplusUsed?: number;
  prevSurplusGained?: number;
  secondaryBuyOutCo2: number;
  secondaryBuyOutCost: number;
}
