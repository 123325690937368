/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PerformanceDataTargetUnitDetails {
  tuIdentifier: string;
  operatorName: string;
  numOfFacilities: number;
  energyCarbonUnit: 'ENERGY_KWH' | 'ENERGY_MWH' | 'ENERGY_GJ' | 'CARBON_KG' | 'CARBON_TONNE';
  throughputUnit?: string;
  byStartDate: string;
  byEnergyCarbon: number;
  byThroughput?: number;
  byPerformance?: number;
  numericalTarget?: number;
  tolerance?: number;
  percentTarget: number;
  tolerancePercentage?: number;
  bankedSurplus: number;
}
