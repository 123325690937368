/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { SubsistenceFeesMoaSearchCriteria } from '../model/subsistenceFeesMoaSearchCriteria';
import { SubsistenceFeesMoaSearchResults } from '../model/subsistenceFeesMoaSearchResults';
import { SubsistenceFeesRunDetailsDTO } from '../model/subsistenceFeesRunDetailsDTO';
import { SubsistenceFeesRunSearchResults } from '../model/subsistenceFeesRunSearchResults';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class SubsistenceFeesRunInfoViewService {
  protected basePath = '/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Retrieves the details for the specific run id
   * @param runId The run id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSubsistenceFeesRunDetailsById(runId: number): Observable<SubsistenceFeesRunDetailsDTO>;
  public getSubsistenceFeesRunDetailsById(
    runId: number,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<SubsistenceFeesRunDetailsDTO>>;
  public getSubsistenceFeesRunDetailsById(
    runId: number,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<SubsistenceFeesRunDetailsDTO>>;
  public getSubsistenceFeesRunDetailsById(
    runId: number,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<SubsistenceFeesRunDetailsDTO>;
  public getSubsistenceFeesRunDetailsById(
    runId: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (runId === null || runId === undefined) {
      throw new Error('Required parameter runId was null or undefined when calling getSubsistenceFeesRunDetailsById.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.get<SubsistenceFeesRunDetailsDTO>(
      `${this.configuration.basePath}/v1.0/subsistence-fees-runs/${encodeURIComponent(String(runId))}`,
      {
        responseType: responseType_ as any,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Retrieves the subsistence fees run MoAs
   * @param runId The runId
   * @param subsistenceFeesMoaSearchCriteria
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSubsistenceFeesRunMoas(
    runId: number,
    subsistenceFeesMoaSearchCriteria: SubsistenceFeesMoaSearchCriteria,
  ): Observable<SubsistenceFeesMoaSearchResults>;
  public getSubsistenceFeesRunMoas(
    runId: number,
    subsistenceFeesMoaSearchCriteria: SubsistenceFeesMoaSearchCriteria,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<SubsistenceFeesMoaSearchResults>>;
  public getSubsistenceFeesRunMoas(
    runId: number,
    subsistenceFeesMoaSearchCriteria: SubsistenceFeesMoaSearchCriteria,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<SubsistenceFeesMoaSearchResults>>;
  public getSubsistenceFeesRunMoas(
    runId: number,
    subsistenceFeesMoaSearchCriteria: SubsistenceFeesMoaSearchCriteria,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<SubsistenceFeesMoaSearchResults>;
  public getSubsistenceFeesRunMoas(
    runId: number,
    subsistenceFeesMoaSearchCriteria: SubsistenceFeesMoaSearchCriteria,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (runId === null || runId === undefined) {
      throw new Error('Required parameter runId was null or undefined when calling getSubsistenceFeesRunMoas.');
    }
    if (subsistenceFeesMoaSearchCriteria === null || subsistenceFeesMoaSearchCriteria === undefined) {
      throw new Error(
        'Required parameter subsistenceFeesMoaSearchCriteria was null or undefined when calling getSubsistenceFeesRunMoas.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.post<SubsistenceFeesMoaSearchResults>(
      `${this.configuration.basePath}/v1.0/subsistence-fees-runs/${encodeURIComponent(String(runId))}/moas`,
      subsistenceFeesMoaSearchCriteria,
      {
        responseType: responseType_ as any,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Retrieves the subsistence fees runs
   * @param page The page number starting from zero
   * @param size The page size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSubsistenceFeesRuns(page: number, size: number): Observable<SubsistenceFeesRunSearchResults>;
  public getSubsistenceFeesRuns(
    page: number,
    size: number,
    observe: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpResponse<SubsistenceFeesRunSearchResults>>;
  public getSubsistenceFeesRuns(
    page: number,
    size: number,
    observe: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<HttpEvent<SubsistenceFeesRunSearchResults>>;
  public getSubsistenceFeesRuns(
    page: number,
    size: number,
    observe: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<SubsistenceFeesRunSearchResults>;
  public getSubsistenceFeesRuns(
    page: number,
    size: number,
    observe: any = 'body',
    reportProgress = false,
    options?: { httpHeaderAccept?: 'application/json' },
  ): Observable<any> {
    if (page === null || page === undefined) {
      throw new Error('Required parameter page was null or undefined when calling getSubsistenceFeesRuns.');
    }
    if (size === null || size === undefined) {
      throw new Error('Required parameter size was null or undefined when calling getSubsistenceFeesRuns.');
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (page !== undefined && page !== null) {
      queryParameters = this.addToHttpParams(queryParameters, page as any, 'page');
    }
    if (size !== undefined && size !== null) {
      queryParameters = this.addToHttpParams(queryParameters, size as any, 'size');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    const credential = this.configuration.lookupCredential('bearerAuth');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType_ = 'text';
    }

    return this.httpClient.get<SubsistenceFeesRunSearchResults>(
      `${this.configuration.basePath}/v1.0/subsistence-fees-runs/`,
      {
        params: queryParameters,
        responseType: responseType_ as any,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
