/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TargetPeriodDTO {
  id?: number;
  businessId?: 'TP5' | 'TP6';
  name?: string;
  startDate?: string;
  endDate?: string;
  performanceDataTemplateVersion?: string;
  performanceDataStartDate?: string;
  performanceDataEndDate?: string;
  buyOutStartDate?: string;
  buyOutEndDate?: string;
  secondaryReportingStartDate?: string;
  current?: boolean;
}
