/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PerformanceDataCalculatedMetrics {
  byPerformance?: number;
  numericalTarget?: number;
  tolerance?: number;
  tpEnergy?: number;
  tpChpDeliveredElectricity?: number;
  tpPerformance?: number;
  tpPerformancePercent?: number;
  tpOutcome?: 'TARGET_MET' | 'BUY_OUT_REQUIRED' | 'SURPLUS_USED_BUY_OUT_REQUIRED' | 'SURPLUS_USED' | 'OUTSTANDING';
  tpCarbonFactor?: number;
  energyCarbonUnderTarget?: number;
  carbonUnderTarget?: number;
  co2Emissions?: number;
  surplusUsed?: number;
  surplusGained?: number;
  priBuyOutCarbon?: number;
  priBuyOutCost?: number;
  secondaryBuyOutCo2?: number;
  secondaryBuyOutCost?: number;
}
