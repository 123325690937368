/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SecondaryMoASurplusBuyOutDTO {
  co2Emissions?: string;
  priBuyOutCarbon?: string;
  prevBuyOutCo2?: string;
  prevSurplusUsed?: string;
  prevSurplusGained?: string;
  secondaryBuyOutCo2?: string;
  secondaryBuyOutCost?: string;
}
